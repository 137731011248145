import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f48cdeb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-xl-3 col-lg-4 col-md-6 col-sm-8" }
const _hoisted_4 = { class: "row outer-box-emloyee" }
const _hoisted_5 = { class: "add-box" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-xl-3 col-lg-4 col-md-6 col-sm-8" }
const _hoisted_8 = { class: "col-xl-3 col-lg-4 col-md-6 col-sm-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.open)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "background",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, [
              _createElementVNode("div", {
                class: "form",
                onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_LabeledInput, {
                        label: _ctx.$t('filter.search'),
                        modelValue: _ctx.searching,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searching) = $event))
                      }, null, 8, ["label", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtered, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "col-lg-4 col-md-6 employee",
                        key: item.id
                      }, [
                        _createTextVNode(_toDisplayString(item.name) + " ", 1),
                        _createElementVNode("span", _hoisted_5, [
                          _createVNode(_component_Checkbox, {
                            modelValue: item.selected,
                            "onUpdate:modelValue": ($event: any) => ((item.selected) = $event)
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ])
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("button", {
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.start && _ctx.start(...args)))
                      }, _toDisplayString(_ctx.$t('filter.start')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("button", {
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)))
                      }, _toDisplayString(_ctx.$t('filter.reset')), 1)
                    ])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}